import React, { useEffect } from 'react';
import {
  DataTerritory,
  Footer,
  Header,
  ReadyToUse,
  TextHolder,
  AboutCard,
  NoiseEffect
} from '@/Components';
import { setThemes } from '@/redux/reducers/theme';
import { useSelector, useDispatch } from 'react-redux';
import Head from 'next/head';

export default function Home() {
  const { theme } = useSelector((state: any) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const colorTheme = localStorage.getItem('color-theme');
    if (colorTheme) {
      if (colorTheme === 'light') {
        dispatch(setThemes('light'));
      } else {
        dispatch(setThemes('dark'));
      }
    }
  }, [dispatch, theme]);
  return (
    <>
      <Head>
        <title>Chronly: Manage your workforce with AI</title>
        <link rel="canonical" href="https://chronly.ai/" />
      </Head>
      <div className="overflow-hidden relative ">
        <NoiseEffect>
          <Header />
          <TextHolder
            title=""
            heading={
              <h1
                className="tracking-subtitleMobileSpacing lg:tracking-subtitleSpacing 
              lg:text-headingLarge md:text-headingLarge2 text-heading1 font-semibold leading-tight mb-4 md:mb-5 
              dark:text-bgLight text-black text-tw-base"
              >
                Enable Tech Team to {''}
                <span className="dark:opacity-[70px] opacity-65 noise-text">
                  Deliver
                </span>
                .
              </h1>
            }
            content={
              <>
                See who&apos;s on track and who needs guidance in{' '}
                <span className="dark:opacity-[70px] opacity-65 noise-text">
                  real-time
                </span>{' '}
                with Chronly.
                <br />{' '}
                <span className="dark:opacity-[70px] opacity-65 noise-text">
                  Instantly
                </span>{' '}
                identify areas for improvement,{' '}
                <span className="font-bold">not months</span> later.
                <br /> Achieve more, staying within your budget.
              </>
            }
          />
          <DataTerritory />
          <AboutCard />
          <ReadyToUse />
          <Footer />
        </NoiseEffect>
      </div>
    </>
  );
}
